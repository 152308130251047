// import { useState } from 'react';
// import { useIonAlert } from '@ionic/react';

export const useInviteApi = () => {

  async function checkCode(code: string) {
    try {
      const response = await fetch(`https://helpful-normally-toad.ngrok-free.app/invited`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'any value',
        },
        body: JSON.stringify({ inviteCode: code }),
      });
      const data = await response.json();

      return data
    } catch (error) {
      return {error: 'bad'}
    }
  }

  return {
    checkCode
  }
};