import { useState } from 'react';
import { useIonAlert } from '@ionic/react';
import { useHistory } from 'react-router';

export const useApi = (botname: string) => {

  const [messages, setMessages] = useState<Array<{sender: string, content: string}>>([
    {sender: 'user', content: '👋👋👋'},
    {sender: 'bot', content: 'hey'},
  ]);

  const [presentAlert] = useIonAlert();
  const [isTyping, setIsTyping] = useState(false);
  const [questionsLeft, set_questionsLeft] = useState(1);
  const history = useHistory();

  const sendMessage = async (message: string) => {
    setMessages(prev => [...prev, { sender: 'user', content: message }]);
    
    let finished = false
    setTimeout(async () => {
      setIsTyping(true);

      if (Math.random() < 0.33)
        setTimeout(() => {
          setIsTyping(false);

            setTimeout(() => {
              if (!finished)
                setIsTyping(true);

            }, 1000+Math.random()*1000)

        }, 1000+Math.random()*1000)

      try {
        // tmp fix
        if (localStorage['PAID'] == '01') localStorage['PAID'] = 1
        if (localStorage['PAID'] == '011') localStorage['PAID'] = 2
    
        let username = localStorage['username'] || '?'
        const response = await fetch(`https://helpful-normally-toad.ngrok-free.app/chat/${botname}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any value',
          },
          body: JSON.stringify({
            message,
            username,
            inviteCode: localStorage['inviteCode'],
            PAID: localStorage['PAID'] || 0,
          }),
        });
        
        const data = await response.json();
        finished = true
        if (data.success) {
          set_questionsLeft(Math.floor(data.remainingCredit/0.05))
          setIsTyping(false);
          setMessages(prev => [...prev, { sender: 'bot', content: data.content }]);
        } else {
          throw new Error(data.error);
        }
      } catch (error) {
        setIsTyping(false);
        presentAlert({
          header: 'Error',
          // @ts-ignore kokot
          message: error.message,
          buttons: [
            {
              text: 'Try again',
            },
            {
              text: 'Ok',
              handler: () => {
                history.push('/home')
              }
            }
          ],
        });
      }
    }, 1000 + Math.random()*1000);
  };

  return { messages, isTyping, questionsLeft, sendMessage };
};
