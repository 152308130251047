import React, { useState } from 'react';
import { IonContent, IonPage, IonInput, IonButton, IonToast } from '@ionic/react';
import './Invite.css';
import confetti from 'canvas-confetti';
import { useInviteApi } from './useInviteApi';
import { useHistory } from 'react-router';

const Invite: React.FC = () => {
  const [name, setName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [showToast, setShowToast] = useState('');
  let history = useHistory()

  let { checkCode } = useInviteApi()

  const handleEnter = async () => {
    const username = name || new Date().toISOString().slice(0, 19).replace('T', ' ');
    localStorage['username'] = username
    localStorage['inviteCode'] = inviteCode

    let r = await checkCode(inviteCode)
    if (r.success) {
      confetti();
      setTimeout(() => {
        history.push('/home');
      }, 1);
      return
    }
    setShowToast(r.error || 'error');
  }

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <form className="ion-text-center invite-container">
          <IonInput
            className="custom-input"
            placeholder="Invite code"
            value={inviteCode}
            type="password"
            onIonInput={e => setInviteCode(e.detail.value!)}
          />
          <IonInput
            className="custom-input"
            placeholder="Your name"
            value={name}
            onIonInput={e => setName(e.detail.value!)}
          />
          <IonButton onClick={handleEnter}>
            Enter
          </IonButton>
        </form>
        <IonToast
          isOpen={!!showToast}
          onDidDismiss={() => setShowToast('')}
          message={showToast}
          duration={2000}
          color="danger"
        />
      </IonContent>
    </IonPage>
  );
};

export default Invite;