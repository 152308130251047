import React, { useState, useEffect } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonBackButton, IonButtons, IonAvatar, IonItem, IonLabel } from '@ionic/react';
import { useParams } from 'react-router-dom';
import './Chat.css';
import { useApi } from './useChatApi';
import MessageInput from './MessageInput';

const Chat: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const { messages, isTyping, questionsLeft, sendMessage} = useApi(username);

  useEffect(() => {
    const scrollToBottom = () => {
      const content = document.querySelector('ion-content');
      content?.scrollToBottom(300);
    };

    scrollToBottom();
    setTimeout(scrollToBottom, 100);
    setTimeout(scrollToBottom, 300);
  }, [messages, isTyping]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="" />
          </IonButtons>
          <IonItem lines="none">
            <IonAvatar slot="start">
              <img src={`/avatar/${username}.png`} alt={username} />
            </IonAvatar>
            <IonLabel>{username} <sup style={{opacity: 0.1}}>{questionsLeft}</sup></IonLabel>
          </IonItem>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="chat-header">
          <IonAvatar className="large-avatar">
            <img src={`/avatar/${username}.png`} alt={username} />
          </IonAvatar>
          <h2>{{psycho: 'Roman Sekerka', sebi: 'Sebastian Kot'}[username]}</h2>
          <p>@{username.toLowerCase()}</p>
        </div>
        <div className="message-list">
          {messages.map((msg, index) => (
            <div key={index} className={`message-container ${msg.sender === 'user' ? 'sent' : 'received'}`}>
              {msg.sender === 'bot' && (
                <IonAvatar className="small-avatar">
                  <img src={`/avatar/${username}.png`} alt={username} />
                </IonAvatar>
              )}
              <div className="message">{msg.content}</div>
            </div>
          ))}
          {isTyping && (
            <div className="message-container received">
              <IonAvatar className="small-avatar">
                <img src={`/avatar/${username}.png`} alt={username} />
              </IonAvatar>
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          )}
        </div>
      </IonContent>
      <MessageInput sendMessage={sendMessage} />
    </IonPage>
  );
};

export default Chat;