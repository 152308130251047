import React from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonIcon, IonText, IonItem, IonAvatar, IonLabel, IonSearchbar, IonButtons, IonButton } from '@ionic/react';
import { personCircle, checkmarkCircle, logOutOutline, logoPaypal } from 'ionicons/icons';

import { useHistory } from 'react-router';

const Home: React.FC = () => {
  const history = useHistory()

  function logout() {
    localStorage['inviteCode'] = '?'
    localStorage['username'] = '?'
    localStorage['PAID'] = 0
    history.push('/')
    // setTimeout(() => {
    //   router.push('/', 'root')
    // }, 1000)
    // setTimeout(() => {
    //   router.push('/')
    // }, 2000)
  }
  let handleUserClick = (botname: string) => {
    history.push(`/chat/${botname}`);
  };

  function pay() {
    history.push(`/pay`);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar style={{"--background": "white", "--border-width": "0px", "--min-height": "60px"}}>
          <IonIcon slot="start" icon={personCircle} style={{fontSize: "150%", color: "#999", marginLeft: "20px"}} />
          <IonText style={{color: "#999", marginLeft: "10px"}}>{localStorage['username']}</IonText>
          <IonButtons slot="end">
            <IonButton onClick={pay}>
             <IonIcon slot="icon-only" icon={logoPaypal}/>
           </IonButton>
           <IonButton onClick={logout}>
            <IonIcon slot="icon-only" icon={logOutOutline} />
           </IonButton>
         </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSearchbar placeholder="Search for cubes" style={{"--box-shadow": "none", "--height": "60px", "--border-radius": "1em"}}></IonSearchbar>
        <div className="ion-padding">
          <IonItem lines="none" style={{"--background": "#f8f8f8", "--border-radius": "1em", marginBottom: '15px', "--min-height": "80px"}}
            onClick={() => handleUserClick('london')}>
            <IonAvatar slot="start">
              <img src="/avatar/london.png" alt="avatar" />
            </IonAvatar>
            <IonLabel>
              <h2 style={{fontWeight: 'bold'}}>London For Students<IonIcon
                color="primary" icon={checkmarkCircle} style={{position: 'relative', top: '2px'}} />
              </h2>
              <p>@london_askcube
              </p>
            </IonLabel>
          </IonItem>
          {/* <IonItem lines="none" style={{"--background": "#f8f8f8", "--border-radius": "1em", "--min-height": "80px"}}
            onClick={() => handleUserClick('psycho')}>
            <IonAvatar slot="start">
              <img src="/avatar/psycho.png" alt="avatar" />
            </IonAvatar>
            <IonLabel>
              <h2 style={{fontWeight: 'bold'}}>Roman Sekerka <IonIcon color="primary" icon={checkmarkCircle} /></h2>
              <p>@psycho</p>
            </IonLabel>
          </IonItem> */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Home;