import React, { useState, useRef, useEffect } from 'react';
import { IonFooter, IonToolbar, IonInput, IonButtons, IonButton, IonIcon } from '@ionic/react';
import { sendOutline } from 'ionicons/icons';

interface MessageInputProps {
  sendMessage: (message: string) => void;
}

const MessageInput: React.FC<MessageInputProps> = ({ sendMessage }) => {
  const [inputMessage, setInputMessage] = useState('');
  const inputRef = useRef<HTMLIonInputElement>(null);

  const handleSendMessage = () => {
    if (!inputRef.current) return;
  
    inputRef.current.blur();
    setTimeout(() => {
      const currentInput = inputRef.current?.value as string;
      if (currentInput && currentInput.trim() !== '') {
        sendMessage(currentInput);
        setInputMessage('');
      }
      inputRef.current?.setFocus();
    }, 50);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    const handleNativeKeyboardEnter = (event: Event) => {
      if ((event as CustomEvent).detail.keyCode === 13) {
        handleSendMessage();
      }
    };

    window.addEventListener('keyboardWillShow', () => {
      document.addEventListener('ionKeyboardDidShow', handleNativeKeyboardEnter);
    });

    return () => {
      document.removeEventListener('ionKeyboardDidShow', handleNativeKeyboardEnter);
    };
  }, [inputMessage]);

  return (
    <IonFooter className="ion-no-border">
      <IonToolbar>
        <IonInput 
          ref={inputRef}
          value={inputMessage}
          onIonChange={e => setInputMessage(e.detail.value!)}
          onKeyDown={handleKeyPress}
          placeholder="Write a message..." 
          style={{
            '--padding-start': '15px',
            '--padding-end': '15px',
            '--border-radius': '20px',
            '--background': '#f1f0f0'
          }}
        ></IonInput>
        <IonButtons slot="end">
          <IonButton className="send-button" onClick={handleSendMessage}>
            <IonIcon icon={sendOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonFooter>
  );
};

export default MessageInput;