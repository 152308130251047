import React, { useEffect, useRef, useState } from 'react';
import { IonContent, IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonBackButton } from '@ionic/react';
import { loadScript } from '@paypal/paypal-js';
import { useHistory } from 'react-router';

const Pay: React.FC = () => {
  const paypalButtonRef = useRef<HTMLDivElement>(null);
  let [paid, set_paid] = useState(parseInt(localStorage['PAID'] || 0))

  let history = useHistory()

  const createOrder = (data: any, actions: any) => {
    return actions.order.create({
      intent: 'CAPTURE',
      purchase_units: [{
        amount: { 
          currency_code: 'GBP',
          value: '1.00' 
        },
      }],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
        locale: 'en-GB'
      }
    });
  };

  const onApprove = async (data: any, actions: any) => {
    if (!actions.order) return;

    const details = await actions.order.capture();
    console.log('Transaction completed by ' + details.payer.name?.given_name);
    // Handle successful payment here
    alert('Success, enjoy!')
    if (localStorage['PAID'] == '01') localStorage['PAID'] = 1
    if (localStorage['PAID'] == '011') localStorage['PAID'] = 2
    localStorage['PAID'] = (localStorage['PAID'] || 0)*1 + 1
    set_paid(parseInt(localStorage['PAID']))
    history.push('/home')
    // setTimeout(() => {
    //   router.push('/home', 'root')
    // }, 1000)
    // setTimeout(() => {
    //   router.push('/home')
    // }, 2000)
  };

  const initPayPalButton = async () => {
    if (!paypalButtonRef.current) return;

    try {
      let CLIENT_ID = 'AbvUHE53P3j2E8-irtlao76Y9h9pW_-G--ol2tikoM9-94G-C9uIrfeCTO7lD6M6otANpvnSeyd1WXSI'
      const paypal = await loadScript({ 
        // @ts-ignore
        'client-id': CLIENT_ID,
        currency: 'GBP',
        locale: 'en_GB',
        components: 'buttons,funding-eligibility'
      });

      if (!paypal?.Buttons || !paypal.FUNDING) return;

      await paypal.Buttons({
        fundingSource: paypal.FUNDING.CARD,
        style: {
          layout: 'vertical',
          color:  'black',
          shape:  'rect',
          label:  'pay'
        },
        createOrder,
        onApprove,
      }).render(paypalButtonRef.current);

      await paypal.Buttons({
        fundingSource: paypal.FUNDING.PAYPAL,
        style: {
          layout: 'vertical',
          color:  'blue',
          shape:  'rect',
          label:  'paypal'
        },
        createOrder,
        onApprove,
      }).render(paypalButtonRef.current);

    } catch (err) {
      // nejaka picovina
      if ((err+'').indexOf('zoid destroyed') > 0) return

      alert('error, sorry mate: '+err);
      console.error('Failed to load the PayPal JS SDK script', err);
    }
  };

  useEffect(() => {
    initPayPalButton();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <IonBackButton defaultHref='/home' />
          </IonButtons>
          <IonTitle>Payment</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        <h2>Enjoying our helpful assistants?</h2>
        <h4>Add 20 questions for £1</h4>
        <p>Added funds: £{paid}</p>
        <div ref={paypalButtonRef}></div>
        <p style={{color: 'red', opacity: 0.5}}>4111111111111111 + future exp + any cvc<br/>4000000000000002 test fail</p>
      </IonContent>
    </IonPage>
  );
};

export default Pay;
